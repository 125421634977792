import React, {
  Suspense,
} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Routes from './Routes';

import 'react-virtualized/styles.css';
import '@myjobglasses/admin-ui/dist/index.css';
import { ErrorBoundary } from 'ErrorBoundary';
import { errorTracker } from 'error-tracker/error-tracker';

const SchoolRouter = React.lazy(() => import('./scenes/schools/SchoolRouter'));
function App() {
  return (
    <ErrorBoundary onErrorCatch={errorTracker.getBoundaryErrorHandler()}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Switch>
            <Route
              path={Routes.schoolAdmin}
              component={SchoolRouter}
            />
            <Redirect
              to={Routes.schoolAdmin}
            />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
